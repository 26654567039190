import { LogLevel } from '../app/classes/enums';

const API_PORT = 4443;

export const environment = {
  production: false,
  hmr: false,
  contactMail: '',
  currentFlow: 'prod',
  MAIN_HOST: 'talent-cloud.ru:' + API_PORT,
  TRACKING_CODE: 'UA-141210688-1',
  STOMP_PORT: 15678,
  WEB_SOCKET_URL: 'wss://talent-cloud.ru',
  G_VERIFICATION1: '',
  G_VERIFICATION2: '',
  FB_VERIFICATION: '',
  FB_PIXEL_ID: '',
  GTM_CODE: '',
  YMETRIKA: '89802880',
  LOG_SETTINGS: {
    LOG_LEVEL: LogLevel.WARN,
    LOG_SERVER_LEVEL: LogLevel.ERROR,
    GLOBAL: false,
  },
  timeStamp: '03-09-2024/12-48/PM-MSK',
  google_client_id: '331418288164-q3j4nurvm2vla0qfsv9jecaocg4k4rjv.apps.googleusercontent.com',
  google_app_name: 'talent-cloud',
  facebook_client_id: '',
  YA_WEBMASTER: '00c721311eb55a9c',
};
